*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
:root {
    color-scheme: light only;
}

body {
    margin: 0px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.42857;
    color: #ffffff;
    touch-action: manipulation;
    background-image: url('images/assets/background5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

input,
button,
textarea,
select {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    margin-top: 10px;
}

h4 {
    margin-bottom: 10px;
}

h5 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.card {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    place-content: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 48px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.question-card {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin: auto;
    color: black;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 32px;
    box-shadow: 0 0 20px rgba(32, 32, 32, 0.2);
    border: 0px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
}

.btn {
    display: inline-block;
    line-height: 50px;
    padding: 0px 15px;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
}

.btn.btn-danger {
    background-color: #d9534f;
    border-color: #d43f3a;
    color: #fff;
}

img {
    max-width: 100%;
}

.page {
    padding: 20px;
}

.has-menu {
    margin-bottom: 60px;
}

.menu {
    position: fixed;
    z-index: 999;
    bottom: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 50%;
    transform: translate(-50%, -0%);
}

.menu .item+.item {
    border-left: 0px solid #909090;
}

.menu .item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
}

.menu .item:active {
    background-color: #909090;
}

.toast-top-center {
    top: 10px;
}

.main-home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.page-home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;
    max-width: 1200px;
}

.page-home .item {
    display: block;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    border-radius: 32px;
    height: 140px;
    width: 140px;
    padding: 10px 10px 10px 10px;
    margin: 10px;
    text-align: center;
    display: inline-grid;
    justify-content: center;
    background: #ffffff92;
    box-shadow: 0 0 4px hsl(0deg 0% 0% / 50%);
    backdrop-filter: blur(1px); 
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.page-home .item2 {
    width: 165px;
    margin: 10px;
    padding-right: 20px;
    text-align: right;
}


.page-home .item-hint {
    background-color: #5750d920
}

.page-home .item-random {
    color: #942092
}

.page-home .item-full {
    color: #008f51
}

.page-home .item-wrong {
    color: rgb(205, 119, 13)
}

.page-home .item-danger {
    color: rgb(220, 5, 5)
}

.page-home .item-exam {
    color: #af52dd
}

.page-home .item-law {
    color: #dc1354
}

.page-home .item-reset {
    color: #505150
}

.page-home .item-sign {
   color: #005392
}

.page-home .item-topic {
    color: rgb(9, 121, 123)
}

.page-home .fa-home-page {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 55px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.head-navigation {
    display: flex;
    box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
    padding: 10px;
    min-height: 56px;
}

.head-navigation .left {
    width: 10%;
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 24px;
    padding-left: 10px;
}

.head-navigation .center {
    width: 80%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.head-navigation .right {
    width: 10%;
    display: flex;
    text-align: right;
    justify-content: right;
    align-items: center;
    font-size: 24px;
    padding-right: 10px;
}

#list-page #list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

#list-page #list a {
    display: inline-block;
    line-height: 50px;
    width: 50px;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 5px;
    box-shadow: 0 0 2px gray;
    border-radius: 12px;
}

#list-page #list a:active {
    background-color: #d0d0d0;
}

#list-page #list a.correct {
    background-color: #449d44;
    border-color: #449d44;
    color: #fff;
}

#list-page #list a.danger {
    border-color: #ff9400;
}

#list-page #list a.correctdanger {
    background-color: #449d44;
    border-color: #ff9400;
    color: #fff;
}

#list-page #list a.correct:active {
    background-color: #1f471f;
    border-color: #1f471f;
}

#list-page #list a.wrong {
    background-color: #dc1354;
    border-color: #dc1354;
    color: #fff;
}

#list-page #list a.wrongdanger {
    background-color: #dc1354;
    border-color: #ff9400;
    color: #fff;
}

#list-page #list a.wrong:active {
    background-color: #922420;
    border-color: #922420;
}

#details-page {
    padding-left: 20px;
    padding-right: 20px;
}

#details-page #q-details {
    font-size: 16px;
    place-content: start;
}

#details-page .answer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
    cursor: pointer;
}

#details-page .answer.correct {
    color: rgb(76, 76, 245);
}

#details-page .answer.wrong {
    color: rgb(246, 76, 76);
}

#details-page .answer:first-child {

}

#details-page .answer .a-text {
    margin-left: 7px;
}

#details-page .nav {
    position: fixed;
    z-index: 999;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    background-color: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#details-page .nav .item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 30px;
}

#details-page .nav .item:active {
    background-color: #909090;
}

#details-page .nav .item+.item {
    border-left: 1px solid #909090;
}

#reset-page a {
    display: inline-block;
    line-height: 50px;
    width: 100px;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
}

#list-wrong-page {
    padding: 0px;
}

#list-wrong-page #list {
    padding: 5px;
    font-size: 16px;
}

#list-wrong-page #list .item+.item {
    margin-top: 30px;
}

#list-wrong-page .answer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
}

#list-wrong-page .answer.correct {
    color: blue;
}

#list-wrong-page .answer.wrong {
    color: red;
}

#list-danger-page #list {
    padding: 5px;
    font-size: 16px;
}

#list-danger-page #list .item+.item {
    margin-top: 30px;
}

#list-danger-page .answer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
}

#list-danger-page .answer.correct {
    color: blue;
}

#list-danger-page .answer.danger {
    color: red;
}

#list-danger-page .answer:first-child {

}

#list-exam-page #list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

#list-exam-page #list #exam {
    display: inline-block;
    height: 150px;
    width: 150px;
    text-align: center;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 15px;
    padding: 10px;
    backdrop-filter: blur(1px); 
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

#list-exam-page #list a:active {
    background-color: #d0d0d0;
}

#list-exam-page #list a.correct {
    background-color: #449d44;
    border-color: #449d44;
    color: #fff;
}

#list-exam-page #list a.danger {
    border-color: #ff9400;
}

#list-exam-page #list a.correctdanger {
    background-color: #449d44;
    border-color: #ff9400;
    color: #fff;
}

#list-exam-page #list a.correct:active {
    background-color: #1f471f;
    border-color: #1f471f;
}

#list-exam-page #list a.wrong {
    background-color: #dc1354;
    border-color: #dc1354;
    color: #fff;
}

#list-exam-page #list a.wrongdanger {
    background-color: #dc1354;
    border-color: #ff9400;
    color: #fff;
}

#list-exam-page #list a.wrong:active {
    background-color: #922420;
    border-color: #922420;
}


/* Put your css in here */

#details-page .overlap {
    position: absolute;
    position: 0px 0px 0px 0px;
}

#details-page .ng-enter {
    animation-name: swipeLeft;
    -webkit-animation-name: swipeLeft;
    -o-animation-name: swipeLeft;
    animation: swipeLeft 0.25s ease-in-out;
    -o-animation: swipeLeft 0.25s ease-in-out;
    -webkit-animation: swipeLeft 0.25s ease-in-out;
}

#details-page .ng-leave {
    animation-name: swipeRight;
    -webkit-animation-name: swipeRight;
    -o-animation-name: swipeRight;
    animation: swipeRight 0.25s ease-in-out;
    -o-animation: swipeRight 0.25s ease-in-out;
    -webkit-animation: swipeRight 0.25s ease-in-out;
}

.new {
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    border-radius: 4px;
    padding: 0 4px 0 4px;
    text-align: center;
    display: inline-grid;
    justify-content: center;
    background-color: darkorange;
    margin: auto;
}


.controls-container {
    display: flex;
    margin: 50px 0 25px;
}

.controls {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.controls input {
    width: 50%;
}

.segment {
    position: relative;
    text-align: center;
    z-index: 1;
}

.segment label {
    cursor: pointer;
    display: block;
    padding: 10px;
    transition: color 0.5s ease;
}

.segment.active label {
    color: #fff;
}

.controls::before {
    content: "";
    background: #5465ff;
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}

.item-container {
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 32px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.item {
    justify-content: space-between;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
}

@keyframes swipeRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes swipeLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes swipeRight {
    0% {
        -webkit-transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

@-webkit-keyframes swipeLeft {
    0% {
        -webkit-transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-o-keyframes swipeRight {
    0% {
        -o-transform: translateX(0);
    }

    100% {
        -o-transform: translateX(-100%);
    }
}

@-o-keyframes swipeLeft {
    0% {
        -o-transform: translateX(-100%);
    }

    100% {
        -o-transform: translateX(0);
    }
}